import React from 'react';
import PropTypes from "prop-types";
import {formatPrice} from '../helpers';

class Project extends React.Component{
  static propTypes ={
    details: PropTypes.shape({
      image: PropTypes.string,
      name: PropTypes.string,
      price: PropTypes.number,
      desc: PropTypes.string,
      status: PropTypes.string,
      url: PropTypes.string
    }),
    addToOrder: PropTypes.func
  }
  handleClick = () =>{
    this.props.addToOrder(this.props.index);
  }
  render(){
    const {image, name, price, desc, status, url} = this.props.details;
    const isOperational = status === 'operational';
    return(
      <li className="menu-fish">
          <img src={image} alt={name} />
          <h3 className ="fish-name"> {name}
              <span className="price">{formatPrice(price)}</span>
          </h3>
          <p>{desc}</p>
          <a href={url}> {url}</a>

          <button disabled={!isOperational} onClick={this.handleClick}> {isOperational ? 'Add To Order' : 'Defunct'}</button>
      </li>
    )
  }
}

export default Project;

import React from 'react';
import PropTypes from 'prop-types';
import {formatPrice} from '../helpers';
import {TransitionGroup, CSSTransition} from "react-transition-group";

class Order extends React.Component{
  static propTypes ={
    projects: PropTypes.object,
    order: PropTypes.object,
    removeFromOrder:PropTypes.func
  }
  renderOrder = (key) => {
    const project = this.props.projects[key];
    const count = this.props.order[key];
    const isAvailable = project && project.status === "operational";
    const transitionOptions = {
        classNames:"order",
        key,
        timeout:{enter: 500, exit:500}
    };
    if(!project) return null;

    if(!isAvailable){
      return(
      <CSSTransition {...transitionOptions}>
        <li key = {key}>
          Sorry, {project ? project.name: 'this project'} is not operational.
        </li>
      </CSSTransition>
      );
    }
    return (
      <CSSTransition {...transitionOptions}>
        <li key = {key}>
          <span>
            <TransitionGroup component="span" className="count">
              <CSSTransition {...transitionOptions}>
                <span>{count} </span>
              </CSSTransition>
            </TransitionGroup>
               {project.name} {formatPrice(count * project.price)}
            <button onClick={() => this.props.removeFromOrder(key)}>&times;</button>
          </span>
        </li>
      </CSSTransition>
    );
  };
  render(){
    const orderIds = Object.keys(this.props.order);
    const total = orderIds.reduce((prevTotal,key)=>{
      const project = this.props.projects[key];
      const count = this.props.order[key];
      const isAvailable = project && project.status === 'operational';
      if(isAvailable){
        return prevTotal + (count * project.price);
      }
      return prevTotal;
    }, 0);

    return(
      <div className="order-wrap">
          <h2>Order</h2>
          <TransitionGroup component="ul" className= "order">
            {orderIds.map(this.renderOrder)}
          </TransitionGroup>
          <div className="total">
              Total:
              <strong>{formatPrice(total)}</strong>
          </div>

          <div>
            <u><h4 id= "ordertxt">Or one developer!</h4></u>
            <img id = "notFound" src="/images/me.jpg" alt=""/>
          </div>

      </div>
    );
  }
}

export default Order;

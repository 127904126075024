import React from 'react';
import PropTypes from 'prop-types';

class EditProjectForm extends React.Component{

  static propTypes = {
    project: PropTypes.shape({
      image: PropTypes.string,
      name: PropTypes.string,
      price: PropTypes.number,
      desc: PropTypes.string,
      status: PropTypes.string,
      url: PropTypes.string
    }),
    index: PropTypes.string,
    updateProject: PropTypes.func,
  }
  handleChange = (event) => {
    const updatedProject = {
      ...this.props.project,
      [event.currentTarget.name]: event.currentTarget.value
    };
    this.props.updateProject(this.props.index, updatedProject)
  }
  render(){
    return(
      <div className="fish-edit">
      <input
        type="text"
        name="name"
        onChange={this.handleChange}
        value={this.props.project.name}
      />
      <input
        type="text"
        name="price"
        onChange={this.handleChange}
        value={this.props.project.price}
      />
      <select
        type="text"
        name="status"
        onChange={this.handleChange}
        value={this.props.project.status}
      >
          <option value="operational"> Operational </option>
          <option value="defunct">Defunct</option>
      </select>
      <textarea
        type="text"
        name="desc"
        onChange={this.handleChange}
        value={this.props.project.desc}
      />
      <textarea
        type="text"
        name="url"
        onChange={this.handleChange}
        value={this.props.project.url}
      />
      <input
        type="text"
        name="image"
        onChange={this.handleChange}
        value={this.props.project.image}
      />
        <button onClick={() => this.props.deleteProject(this.props.index)}>Remove Project</button>
      </div>
    )
  }
}


export default EditProjectForm;

const sampleProjects = {
  project1: {
    name: "Paint By Tic-Tac-Toe",
    image: "/images/paintByTTT.jpg",
    desc:
      "Paint by playing Tic Tac Toe",
    url: "http://koipondkeepers.github.io/",
    price: 7241,
    status: "operational"
  },

  project2: {
    name: "StackChan",
    image: "/images/stackchan.jpg",
    desc:
      "A project about projects!",
    url: "https://stackchan.herokuapp.com/",
    price: 9832,
    status: "operational"
  },

  project3: {
    name: "BuzzBites",
    image: "/images/buzzbites.jpg",
    desc:
      "Looking for someplace to eat? Check buzzbites first!",
    url: "https://buzzbites.herokuapp.com/",
    price: 6841,
    status: "defunct"
  },

  project4: {
    name: "Reduxstagram",
    image: "/images/reduxstagram.jpg",
    desc:
      "An instagram copycat using redux",
    url: "https://reduxstagramlinvic.netlify.com/",
    price: 9211,
    status: "operational"
  },

  project5: {
    name: "Catch of the Day",
    image: "/images/catchOftheday.jpg",
    desc:
      "Sample React app with authentication",
    url: "http://catchofthedaylinvic.netlify.com/",
    price: 8888,
    status: "operational"
  }
};

export default sampleProjects;

import React from 'react';


const NotFound = () => (
  <div>
    <img id ="notFound" src="https://i.imgur.com/6bai9Zc.jp" alt=""/>

  </div>
)


export default NotFound;

import React from "react";
import PropTypes from "prop-types";
import Header from './Header';
import Order from './Order';
import Inventory from './Inventory';
import sampleProjects from '../sample-fishes';
import Project from './Project'
import base from "../base";

class App extends React.Component{
  state = {
    projects: {},
    order: {}
  }

  static propTypes = {
    match: PropTypes.object
  }
  componentDidMount(){
      const {params} = this.props.match;
      const localStorageRef = localStorage.getItem(params.storeId);
      if(localStorageRef){
        this.setState({order: JSON.parse(localStorageRef)})
      }
      this.ref = base.syncState(`${params.storeId}/projects`,{
        context: this,
        state: 'projects'
      });
  }

  componentDidUpdate(){
    localStorage.setItem(this.props.match.params.storeId, JSON.stringify(this.state.order));
  }

  componentWillUnmount(){
    base.removeBinding(this.ref);
  }

  addProject = (project) => {
    const projects = {...this.state.projects};
    projects[`project${Date.now()}`] = project;
    this.setState({projects});
  };

  updateProject = (key, updatedProject) => {
    const projects = {...this.state.projects};
    projects[key] = updatedProject;
    this.setState({projects});
  }

  deleteProject = (key) => {
    const projects = {...this.state.projects};
    projects[key] = null;
    this.setState({projects});
  }

  loadSampleProjects = () => {
    this.setState({projects: sampleProjects});
  }

  addToOrder = (key) => {
      const order = {...this.state.order};
      order[key] = order[key] + 1 || 1;
      this.setState({order});
  }

  removeFromOrder = key => {
      const order = {...this.state.order};
      delete order[key];
      this.setState({order});
  }

  render(){
    return(
      <div className="catch-of-the-day">
          <div className="menu">
            <Header tagline = "Fresh Code Daily"/>
            <ul className="fishes">
                  {Object.keys(this.state.projects).map(key => (
                   <Project
                      key = {key}
                      index = {key}
                      details={this.state.projects[key]}
                      addToOrder={this.addToOrder}
                    />
                  ))}
            </ul>
          </div>
          <Order projects = {this.state.projects} order={this.state.order} removeFromOrder={this.removeFromOrder}/>
          <Inventory
            addProject ={this.addProject}
            updateProject ={this.updateProject}
            deleteProject ={this.deleteProject}
            loadSampleProjects ={this.loadSampleProjects}
            projects = {this.state.projects}
          />
      </div>
    );
  }
}

export default App;

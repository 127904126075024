import React from 'react';
import PropTypes from 'prop-types';

class AddProjectForm extends React.Component{
  nameRef = React.createRef();
  priceRef = React.createRef();
  statusRef = React.createRef();
  descRef = React.createRef();
  urlRef = React.createRef();
  imageRef  = React.createRef();

  static propTypes ={
      addProject: PropTypes.func
  }

  createProject = (event) =>{
    event.preventDefault();
    const project ={
      name: this.nameRef.value.value,
      price: parseFloat(this.priceRef.value.value),
      status: this.statusRef.value.value,
      desc: this.descRef.value.value,
      image: this.imageRef.value.value,
      url: this.urlRef.value.value,
    }
    this.props.addProject(project);

    event.currentTarget.reset();
  }

  render(){
    return(
      <form className="fish-edit" onSubmit={this.createProject}>
        <input name="name" ref={this.nameRef}type = "text" placeholder="Project Name"/>
        <input name="price" ref={this.priceRef}type = "text" placeholder="Cost"/>
        <select name="status"ref={this.statusRef}>
            <option value="operational"> Operational </option>
            <option value="defunct">Defunct</option>
        </select>
        <textarea name="desc" ref={this.descRef}placeholder="Desc"/>
        <textarea name="url" ref={this.urlRef}placeholder="URL"/>
        <input name="image" ref={this.imageRef}type = "text" placeholder="Image"/>
        <button type ="submit"> + Add Project</button>
      </form>
    )
  }
}

export default AddProjectForm;

import React from 'react';
import PropTypes from 'prop-types';
import AddProjectForm from './AddProjectForm';
import EditProjectForm from './EditProjectForm';

class Inventory extends React.Component{
  static propTypes ={
    projects: PropTypes.object,
    updateProject: PropTypes.func,
    deleteProject: PropTypes.func,
    loadSampleProjects: PropTypes.func,
  }
  render(){
    return(
      <div className="inventory">
            <h2>Inventory</h2>
            {Object.keys(this.props.projects).map(key => (
              <EditProjectForm
                project={this.props.projects[key]}
                key={key}
                index={key}
                updateProject ={this.props.updateProject}
                deleteProject ={this.props.deleteProject}
              />
            ))}
            <AddProjectForm addProject = {this.props.addProject}/>
            <button onClick= {this.props.loadSampleProjects}> Load Sample Projects</button>
      </div>
    )
  }
}

export default Inventory;

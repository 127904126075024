export function formatPrice(cents) {
  return (cents / 100).toLocaleString("en-US", {
    style: "currency",
    currency: "USD"
  });
}

export function rando(arr) {
  return arr[Math.floor(Math.random() * arr.length)];
}

export function slugify(text) {
  return text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, "-")
    .replace(/[^\w-]+/g, "")
    .replace(/--+/g, "-")
    .replace(/^-+/, "")
    .replace(/-+$/, "");
}

export function getFunName() {
  const names = [
    "Sage",
    "Lesli",
    "Erlene",
    "Lore",
    "Lynn",
    "Melinda",
    "Joetta",
    "Mark",
    "Basil",
    "Janee",
    "Charlyn",
    "Nathalie",
    "Richard",
    "Annamae",
    "Tama",
    "Ola",
    "Kaitlin",
    "Aurea",
    "Rowena",
    "Rozanne",
    "Francis",
    "Pat",
    "Tami",
    "Sigrid",
    "Felipe",
    "Keeley",
    "Mazie",
    "Nelson",
    "Royal",
    "Reba",
];

  const adjective = [
    "Attractive",
    "Muscular",
    "Beautiful",
    "Chubby",
    "Clean",
    "Dazzling",
    "Elegant",
    "Drab",
    "Fancy",
    "Flabby",
    "Glamorous",
    "Gorgeous",
    "Handsome",
    "Magnificent",
    "Plain",
    "Plump",
    "Long",
    "Scruffy",
    "Fit",
    "Short"
];

let name = rando(names);
let adj = rando(adjective);
return `${adj}-${name}`;
}

import React from 'react';
import PropTypes from 'prop-types'
import {getFunName} from '../helpers'

class StorePicker extends React.Component{

  myInput = React.createRef();
  static propTypes ={
    history: PropTypes.object
  }

  goToStore = (event) => {
    event.preventDefault();
    const storeName = this.myInput.current.value;
    this.props.history.push(`/store/${storeName}`);
  };
  render(){
    return (
      <form className="store-selector" onSubmit={this.goToStore}>
        <h2> Please enter your name</h2>
        <input
          type="text"
          required placeholder="Name"
          defaultValue={getFunName()}
          ref = {this.myInput}
        />
        <button type = "submit">Visit Portfolio</button>
      </form>
    )
  }
}

export default StorePicker;
